import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import ImageOrVideo from '@components/ui/ImageOrVideo/ImageOrVideo';
import Link from '@components/ui/Link';
import { isLife360Enabled } from '@lib/storefront';

type Props = { className?: string; onClick?: () => void };

export const LogoButton: FC<Props> = ({ className, onClick }) => {
  const { t } = useTranslation('common');
  const logoAlt = isLife360Enabled() ? t('common:navigation.logoAltLife360') : t('common:navigation.logoAlt');
  const desktopLogoUrl = isLife360Enabled() ? '/svgs/life360-logo-dark.svg' : '/svgs/life360-tile-logo.svg';
  const mobileLogoUrl = isLife360Enabled() ? '/svgs/life360-logo-dark.svg' : '/svgs/life360-tile-logo-mobile.svg';

  return (
    <Link data-testid="logo-home-link" href="/" title="home" onClick={onClick} className={className}>
      <span className="sr-only">Homepage</span>
      <ImageOrVideo
        srcSet={{
          mobile: {
            url: mobileLogoUrl,
            type: 'image',
            alt: logoAlt,
            height: 50,
            width: 70,
          },
          desktop: {
            url: desktopLogoUrl,
            type: 'image',
            alt: logoAlt,
            height: 32,
            width: 83,
          },
        }}
      />
    </Link>
  );
};
