import { faShoppingCart } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'next-i18next';

import { LineItem } from '@commerce/types/cart';
import CartOverlay from '@components/cart/CartOverlay/CartOverlay';
import Button from '@components/ui/Button/Button';
import { useUI } from '@components/ui/context';
import { useCart } from '@framework/cart';

import s from './button.module.scss';

export const CartButton = () => {
  const { t } = useTranslation('common');
  const ui = useUI();
  const { data: cart } = useCart();
  const itemsCount = cart?.lineItems.reduce((count: number, item: LineItem) => count + item.quantity, 0) ?? 0;
  const inApp = ui.globalParams.inapp === 1;

  return (
    <div className={s.cart}>
      <Button
        href="/cart"
        variant="link"
        className={s.cartIcon}
        prefetch={false}
        clearQuery
        aria-label={t('common:navigation.cartPage')}
      >
        <FontAwesomeIcon className="menu-icon text-black" icon={faShoppingCart} aria-hidden />
        {itemsCount > 0 && <span className={s.itemsCount}>{itemsCount}</span>}
      </Button>
      {!inApp && ui.displayCartOverlay && <CartOverlay />}
    </div>
  );
};
